@tailwind base;
@tailwind components;
@tailwind utilities;

.firebase-emulator-warning {
  display: none;
  background-color: transparent !important;
  border: none !important;
  color: rgba(245, 66, 66, 0.3) !important;
}

html {
  /* height: 100vh;
  width: 100vw; */
  /* overflow: hidden; */
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: white;
  /* position: relative; */
  height: 100%;
  width: 100%;
  /* overflow: hidden; */
  /* position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; */
}

html,
body {
  overscroll-behavior: none;
}

#root {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

* {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
